body {
  background-color: #07051f !important;
  color: #fff !important;
}

.header-clear-medium {
  padding-top: calc(calc(55px + 15px) + (env(safe-area-inset-top)) * 0.8) !important;
}

a {
  color: #ffffff !important;
  text-decoration: underline;
}

.member_center_lvl_6 {
  background: linear-gradient(0deg, rgba(242, 207, 110, 1) 0%, rgba(239, 180, 71, 1) 0%, rgba(167, 103, 17, 1) 100%);
}

.borderAdd:before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  height: 0;
  border-top: 29px solid white;
  border-bottom-right-radius: 100%;
  border-bottom-left-radius: 100%;
}

.full-width {
  width: 100%;
}

.primary {
  color: #007bff;
}
.secondary {
  color: #6c757d;
}
.success {
  color: #28a745;
}
.danger {
  color: #dc3545;
}
.warning {
  color: #ffc107;
}
.info {
  color: #17a2b8;
}
.light {
  color: #f8f9fa;
}
.dark {
  color: #343a40;
}
.muted {
  color: #6c757d;
}
.white {
  color: #ffffff;
}

.slick-slide img {
  margin: auto;
  width: 100%;
  height: 25%;
}

.ant-btn-loading-icon {
  margin-right: 10px;
}

.Toastify__toast-theme--light,
.Toastify__toast {
  background: #4b4f5c;
  color: #fff;
}

.Toastify__close-button {
  color: #ffffff;
  opacity: 0.5;
}

.Toastify__toast {
  font-family: "Nunito", sans-serif !important;
}

.jconfirm-holder {
  color: #000;
}

.rc-virtual-list {
  z-index: 9999999999;
}

.ant-select-selection-search {
  display: none;
}

.MuiSelect-icon.MuiSelect-iconOutlined {
  fill: #ffffff !important;
}

.MuiSelect-select {
  width: auto !important;
}

.MuiInputBase-input {
  padding: 5px 32px 5px 10px !important;
  width: 100% !important;
  text-align: left;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
  border-width: 0.5px !important;
  top: 0px !important;
}

.MuiOutlinedInput-notchedOutline legend {
  display: none !important;
}

.common_textbox .form-control {
  background-color: transparent;
  color: white;
  width: 50px;
  text-align: center;
}

.sell_btn_order_ {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2px 10px;
  background: #198754;
  width: 100% !important;
  border-radius: 5px;
  margin-top: 5px;
  justify-content: center;
  max-width: 75px;
}

.border_bottom_active {
  border-bottom: 2px solid #2e303d;
}
